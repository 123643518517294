<template>
  <div class="programs-category program-masonry-gallery pb-5">

    <section class="masonry-gallery mb-5">
      <h2><i class="fas fa-images"></i> {{cfgTitle}}</h2>
      <hr class="line sm">

      <div 
        v-masonry 
        transition-duration="0.3s" 
        item-selector=".item" 
        horizontal-order="true" 
        origin-top="true"
        class=""
      >
        <div
          v-masonry-tile 
          class="item" 
          v-for="(item,index) in cfgArrayGallery" 
          :key="index"
          @click="$bvModal.show('modal-'+item.id)"
        >
          <b-img
            fluid-grow
            
            :src="`${path.storage_files + item.photo.formats.small.url }`" 
            :alt="`${item.title}`"
            class="z-depth-1 img"></b-img>
        </div>
      </div>

      <b-modal
        v-for="(modal,index) in cfgArrayGallery" :key="index" 
        :id="`modal-${modal.id}`" 
        size="lg" 
        :title="`${modal.title}`"
        hide-header-close
        hide-footer
      >
        <b-img
          fluid-grow
          :src="`${path.storage_files + modal.photo.url }`" 
          :alt="`${modal.title}`"
          class="z-depth-0 img"></b-img>
        <b-button class="mt-3 btn-right" variant="link" @click="$bvModal.hide('modal-'+modal.id)">
          <i class="fas fa-times-circle fa-2x"></i>
        </b-button>
      </b-modal>

      
    </section>

  </div>
</template>

<script>

export default {
  name: 'MasonryGallery',

  components: {

  },

  props: {
    cfgTitle: String,
    cfgCategory: String,
    cfgArrayGallery: Array,
  },

  data() {
    return {
      toggler: false,
      modalShow:{
        title:'',
        image:'',
      },
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },

    }
  },

  methods: {
  },

}
</script>
<style>
  .modal-dialog .btn-right{
    float: right;
  }
</style>
