<template>
  <section class="card-module mb-5">
    
    <h2><i class="fa-solid fa-bars-staggered"></i> {{ cfgTitle ? cfgTitle : 'Historico' }}</h2>
    <hr class="line sm">

    <b-row>
      <b-col
        v-for="(item,i) in cfgArrayAccordion"
        :key="i"
        col lg="4" md="6" sm="12"
      >
        <b-card
          :title="item.accordion_title"
          :img-src="`${ item.image_cover ? path.storage_files + item.image_cover.url : 'https://picsum.photos/600/300/?image=25' }`"
          :img-alt="item.accordion_title"
          img-top
          class="mb-2"
        >
          <b-card-text></b-card-text>
          <b-button 
            class="text-uppercase float-right"
            size="sm"
            v-b-modal="'modalCardAccordion'" 
            itemCard="'item'" 
            @click="sendInfo(item)"
          >
            Mas Detalles
          </b-button>
        </b-card>
      </b-col>
    </b-row>


    <b-modal
      id="modalCardAccordion"
      size="lg"
      :title="`${selectedItem.accordion_title}`"
      ok-only
      scrollable
      centered
      hide-footer
      :style="`padding:20px`"
    >
      <div class="mx-4 my-4">

        <b-img
          fluid-grow
          :src="`${ selectedItem.image_cover ? path.storage_files + selectedItem.image_cover.url : 'https://picsum.photos/600/300/?image=25' }`"
          :alt="`${ selectedItem.accordion_title }`"
          :title="`${ selectedItem.accordion_title }`"
          class="z-depth-3 box-10 mb-4"
        ></b-img>

        <vue-simple-markdown :source="`${selectedItem.accordion_content}`"></vue-simple-markdown>

      </div>
    </b-modal>



  </section>
</template>

<script>

  export default {
    name: 'CardBody',
  
    components: {
  
    },
  
    props: {
      cfgTitle: String,
      cfgExternalContent: String,
      cfgArrayAccordion: Array,
    },
  
    data() {
      return {
        selectedItem:[],
        path: {
          base_url: process.env.VUE_APP_BASE_URL,
          endpoint: process.env.VUE_APP_ENDPOINT,
          storage_files: process.env.VUE_APP_STORAGE_FILES,
        },
        cfg:{
          showMenu: this.$route.meta.cfgShowMenu,
          category: this.$route.meta.cfgCategory,
          nameCategory: this.$route.meta.cfgNameCategory,
        },
  
      }
    },
  
    methods: {
      
      sendInfo(item) {
        this.selectedItem = item;
        console.log("🚀 ~ file: CardBody.vue ~ line 126 ~ sendInfo ~ this.selectedItem", this.selectedItem)
      }
    },
  
  }
  </script>
