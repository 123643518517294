<template>
  <div class="programs-category program-carousel-gallery">

    <h2><i class="fab fa-youtube"></i> {{ cfgTitle }}</h2>
    <hr class="line sm">

    <b-card no-body class="mb-5">
      <b-tabs card>
        <b-tab
          v-for="(video,index) in cfgArrayGallery"
          :key="index"
          :title="`${video.title}`"
          class="p-4"
        >
          <b-card-title>{{ video.description }}</b-card-title>
          <b-card-text>
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="`https://www.youtube-nocookie.com/embed/${video.youtube_id}?rel=0`"
              allowfullscreen
              class="video box-10 z-depth-2"
            ></b-embed>
            <!--<p class="text-center mt-1 strong"> {{ video.description }}</p>-->
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>

  </div>
</template>

<script>

export default {
  name: 'CarouselGallery',

  props: {
    cfgTitle: String,
    cfgCategory: String,
    cfgArrayGallery: Array,
  },

  components: {},

  data() {
    return {
      slide: 0,
      sliding: null,
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },

    }
  },


  methods: {
  }
 
}
</script>
