import http from "@/services/http-common";

class DataTimeline {

    getAll() {
        return http.get('/timelines/');
    }

    get(id) {
        return http.get(`/timelines/${id}`);
    }

}

export default new DataTimeline();
