<template>
  <section class="mt-5 mb-5">
    <h2>
      <i class="fa-solid fa-timeline"></i>
      {{ timeline.titleComponent ? timeline.titleComponent : "Historico" }}
    </h2>
    <hr class="line sm" />
    <div class="row">
      <div class="col-md-12">
        <div class="main-timeline">
          <div v-for="(item, i) in timeline.Times" :key="i" class="timeline">
            <span class="timeline-icon"></span>
            <span class="year" :class="item.titleColor">
              {{ item.dateTime | moment("YYYY-MM-DD ") }}
              <span v-if="item.endDateTime" class="super-light">
                / {{ item.endDateTime | moment("YYYY-MM-DD ") }}
              </span>
            </span>
            <div class="timeline-content">
              <h3 class="title">{{ item.title }}</h3>
              <vue-simple-markdown
                class="description"
                :source="`${item.content}`"
              ></vue-simple-markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DataTimeline from "@/services/data-timeline";

export default {
  name: "TimelineCentral",

  components: {},

  props: {
    cfgPostId: Number,
  },

  data() {
    return {
      timeline: {},
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg: {
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    };
  },

  mounted() {
    this.getTimeline(this.cfgPostId);
  },

  methods: {
    getTimeline(id) {
      DataTimeline.get(id)
        .then((response) => {
          this.timeline = response.data;
          console.log(
            "🚀 ~ file: Central.vue:164 ~ getTimeline ~ this.timeline :",
            this.timeline
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
