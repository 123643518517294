<template>
  <section class="accordion-module mb-5">
    
    <h2><i class="fa-solid fa-bars-staggered"></i> {{ cfgTitle ? cfgTitle : 'Historico' }}</h2>
    <hr class="line sm">

    <div v-for="(item,i) in cfgArrayAccordion" :key="i">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <!--<h5 v-b-toggle="`accordion-${i}`" variant="info">{{ item.accordion_title }}</h5>-->
          <b-button class="dorado-obscuro strong" size="lg" block v-b-toggle="`accordion-${i}`">
            {{ item.accordion_title }}
          </b-button>
        </b-card-header>
        <b-collapse :id="`accordion-${i}`"  accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <vue-simple-markdown :source="`${item.accordion_content}`"></vue-simple-markdown>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

  </section>
</template>

<script>

  export default {
    name: 'AccordionBody',
  
    components: {
  
    },
  
    props: {
      cfgTitle: String,
      cfgExternalContent: String,
      cfgArrayAccordion: Array,
    },
  
    data() {
      return {
        path: {
          base_url: process.env.VUE_APP_BASE_URL,
          endpoint: process.env.VUE_APP_ENDPOINT,
          storage_files: process.env.VUE_APP_STORAGE_FILES,
        },
        cfg:{
          showMenu: this.$route.meta.cfgShowMenu,
          category: this.$route.meta.cfgCategory,
          nameCategory: this.$route.meta.cfgNameCategory,
        },
  
      }
    },
  
    methods: {
    },
  
  }
  </script>
